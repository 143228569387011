/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Button,
  Checkbox,
  Heading,
  Modal,
  Paragraph,
  TextLink,
} from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import {
  TypeToast,
  useAuditLog,
  useAuthenticationService,
  useToast,
  useUserMetadata,
} from 'admin-portal-shared-services';
import Api from 'Api/Api';
import { useEnvContext } from 'contexts';
import { useTermsAndPrivacy } from 'hooks';
import useErrors from 'hooks/useErrors/useErrors';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigateLogout } from '../../hooks/useNavigateLogout';

export default function TermsAndPoliciesModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isPrivacyAccepted, setIsPrivacyAccepted] = useState(false);
  const {
    isFirstAccess,
    shouldOpenTermsAndPrivacyModal: shouldOpenModal,
    isTermsUpdated,
    isPrivacyUpdated,
  } = useTermsAndPrivacy();

  const { formatMessage } = useIntl();
  const logout = useNavigateLogout();
  const { getErrorMessageByFieldName, removeError, setError } = useErrors();
  const { data, mutateUserMetadata } = useUserMetadata();
  const { termsAndConditions, privacyPolicy } = useEnvContext();
  const toastService = useToast();
  const audtiLog = useAuditLog('ACCOUNT');
  const authenticationService = useAuthenticationService();

  const hasTermsAndPrivacyProps = !!termsAndConditions && !!privacyPolicy;

  function sortByDate(a, b) {
    return new Date(a).getTime() - new Date(b).getTime();
  }

  const latestTermsDate =
    hasTermsAndPrivacyProps &&
    Object.keys(termsAndConditions)?.sort(sortByDate)[
      Object.keys(termsAndConditions).length - 1
    ];

  const latestPrivacyDate =
    hasTermsAndPrivacyProps &&
    Object.keys(privacyPolicy)?.sort(sortByDate)[
      Object.keys(privacyPolicy).length - 1
    ];

  const privacyPolicyUrl = privacyPolicy?.[latestPrivacyDate];
  const termsAndConditionsUrl = termsAndConditions?.[latestTermsDate];

  useEffect(() => {
    setIsModalOpen(shouldOpenModal);
  }, [shouldOpenModal]);

  async function patchUserMetadata(patchData) {
    return Api.patch('/api/v1/user-service/users/me', patchData);
  }

  function createPatchData(...args) {
    return args
      .map((term) => {
        if (!term) {
          return;
        }

        return {
          op: 'add',
          path: '/references/acceptedTerms/0',
          value: JSON.stringify(term),
        };
      })
      .filter((term) => term !== undefined);
  }

  function validateForm() {
    if (isFirstAccess) {
      return isTermsAccepted && isPrivacyAccepted;
    }

    if (isTermsUpdated && isPrivacyUpdated) {
      return isTermsAccepted && isPrivacyAccepted;
    }

    if (isTermsUpdated) {
      return isTermsAccepted;
    }

    if (isPrivacyUpdated) {
      return isPrivacyAccepted;
    }
  }

  async function handleSubmit(event) {
    try {
      event.preventDefault();

      if (!isTermsAccepted && isTermsUpdated) {
        setError({
          field: 'terms',
          message: formatMessage({ id: 'TermsAndPolicies.termsError' }),
        });
      }

      if (!isPrivacyAccepted && isPrivacyUpdated) {
        setError({
          field: 'policies',
          message: formatMessage({ id: 'TermsAndPolicies.policiesError' }),
        });
      }

      const isFormValid = validateForm();

      if (!isFormValid) {
        return;
      }

      const newTermAccepted = {
        type: 't&c',
        date: latestTermsDate,
        pdf: termsAndConditionsUrl,
      };

      const newPrivacyAccepted = {
        type: 'privacy',
        date: latestPrivacyDate,
        pdf: privacyPolicyUrl,
      };

      const patchData = createPatchData(
        isTermsAccepted && newTermAccepted,
        isPrivacyAccepted && newPrivacyAccepted,
      );

      await patchUserMetadata(patchData);
      setIsModalOpen(false);

      const newAcceptedTerms = [];

      const metadata = new Object({
        date: new Date().toISOString(),
      });
      if (isTermsAccepted) {
        newAcceptedTerms.push(JSON.stringify(newTermAccepted));
        metadata['t&cDate'] = newTermAccepted.date;
        metadata['t&cVersion'] = newTermAccepted.pdf;
      }

      if (isPrivacyAccepted) {
        newAcceptedTerms.push(JSON.stringify(newPrivacyAccepted));
        metadata['privacyDate'] = newPrivacyAccepted.date;
        metadata['privacyVersion'] = newPrivacyAccepted.pdf;
      }

      await mutateUserMetadata({
        ...data,
        acceptedTerms: newAcceptedTerms,
      });
      audtiLog({
        country: authenticationService.getUserCountryAndLanguage().user_country,
        operation: 'INSERT',
        entityId: data.userId,
        entity: 'TERMS_AND_CONDITIONS',
        metadata: metadata as any,
      });
    } catch (error) {
      toastService.notify({
        type: TypeToast.ERROR,
        message: formatMessage({
          id: 'Error500.title',
        }),
      });
    }
  }

  function handleTermsChange(checked) {
    setIsTermsAccepted(checked);

    if (checked) {
      removeError('terms');
    }
  }

  function handlePoliciesChange(checked) {
    setIsPrivacyAccepted(checked);

    if (checked) {
      removeError('policies');
    }
  }

  return (
    <StyledModal>
      <Modal.Root
        variant="overlay"
        open={isModalOpen}
        actions={
          <StyledActionsButtons>
            <Modal.Cancel>
              <Button
                size="large"
                css={{ marginRight: '$4' }}
                variant="secondary"
                onClick={logout}
                data-testid="declineButton"
              >
                <FormattedMessage id="TermsAndPolicies.declineButton" />
              </Button>
            </Modal.Cancel>
            <Modal.Action>
              <Button
                size="large"
                variant="primary"
                type="submit"
                form="termsAndPoliciesForm"
                data-testid="agreeButton"
              >
                <FormattedMessage id="TermsAndPolicies.agreeButton" />
              </Button>
            </Modal.Action>
          </StyledActionsButtons>
        }
        title={
          <Heading>
            <FormattedMessage
              id={`TermsAndPolicies.${
                isFirstAccess ? 'titleFirstLogin' : 'titleUpdate'
              }`}
            />
          </Heading>
        }
      >
        <StyledForm
          onSubmit={handleSubmit}
          noValidate
          id="termsAndPoliciesForm"
        >
          <Paragraph>
            <FormattedMessage id="TermsAndPolicies.descriptionParagraph1" />
          </Paragraph>
          <br />
          <Paragraph>
            <FormattedMessage id="TermsAndPolicies.descriptionParagraph2" />
          </Paragraph>
          {(isFirstAccess || isTermsUpdated) && (
            <Checkbox
              // @ts-ignore
              label={
                <Paragraph>
                  <FormattedMessage
                    id="TermsAndPolicies.agreeTerms"
                    values={{
                      link: (
                        <TextLink
                          href={termsAndConditionsUrl}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <FormattedMessage id="TermsAndPolicies.termsLink" />
                        </TextLink>
                      ),
                    }}
                  />
                </Paragraph>
              }
              id="terms"
              data-testid="termsCheckbox"
              name="termsCheckbox"
              onCheckedChange={handleTermsChange}
              error={getErrorMessageByFieldName('terms')}
              css={{ marginTop: '$6' }}
            />
          )}
          {(isFirstAccess || isPrivacyUpdated) && (
            <Checkbox
              // @ts-ignore
              label={
                <Paragraph>
                  <FormattedMessage
                    id="TermsAndPolicies.agreePolicies"
                    values={{
                      link: (
                        <TextLink
                          href={privacyPolicyUrl}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <FormattedMessage id="TermsAndPolicies.policiesLink" />
                        </TextLink>
                      ),
                    }}
                  />
                </Paragraph>
              }
              id="policies"
              data-testid="policiesCheckbox"
              name="policiesCheckbox"
              onCheckedChange={handlePoliciesChange}
              error={getErrorMessageByFieldName('policies')}
              css={{ marginTop: '$4' }}
            />
          )}
        </StyledForm>
      </Modal.Root>
    </StyledModal>
  );
}

const StyledModal = styled('div', {
  '& > div > div:first-child': {
    height: 'auto',
  },
});

const StyledForm = styled('form', {
  width: '100%',
  margin: '0',
  '& input, & button': {
    width: '20px',
    height: '20px',
    minWidth: '20px',
    minHeight: '20px',
  },
});

const StyledActionsButtons = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
});
