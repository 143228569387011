import { LoadingDots } from '@hexa-ui/components';
import { Button, Dimmer, Divider, Typography } from '@hexa-ui/components2';
import { EmptyState } from '@hexa-ui/illustrations';
import AppIcon from 'components/AppIcon/AppIcon';
import { getFirstPath } from 'helpers';
import { AppMenuItem } from 'hooks';
import { useAnalyticsElementClicked } from 'hooks/useAnalyticsElementClicked';
import useGetTools from 'hooks/useGetTools/useGetTools';
import { Tool } from 'models/Tool';
import { Fragment, RefObject, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { ElementLocationType, ElementType } from '../../../../../analytics';
import {
  ButtonContainer,
  CategoryTitle,
  Column,
  Container,
  ExceptionContainer,
  MenuButton,
  MenuItem,
  MenuItems,
} from './AppSwitcher.styles';

type CategoryApps = {
  identifier: string;
  name: string;
  icon: string;
  appRoute: string;
  id: string;
  defaultAppName?: string;
};

type userApps = {
  name: string;
  apps: CategoryApps[];
};

type AppSwitcherProps = {
  appsWithScope: AppMenuItem[];
  containerRef: RefObject<HTMLDivElement>;
  handleClose: () => void;
  open: boolean;
};

const AppSwitcher = ({
  appsWithScope,
  containerRef,
  handleClose,
  open,
}: AppSwitcherProps) => {
  const { tools, loading, hasError, fetchTools } = useGetTools();
  const { formatMessage } = useIntl();
  const location = useLocation();
  const currentPath = getFirstPath(location.pathname);
  const analyticsElementClicked = useAnalyticsElementClicked();

  useEffect(() => {
    if (open) {
      document.body.classList.add('no-scroll');
      containerRef.current?.focus();
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [open, containerRef]);

  const groupAppsByCategory = (tools: Tool[], appsWithScope: AppMenuItem[]) => {
    return tools.reduce((acc, appGroup) => {
      appGroup.apps.forEach((app) => {
        const match = appsWithScope.find(
          (allowedApp) => allowedApp.appId === app.identifier,
        );
        if (match) {
          if (!acc[appGroup.name]) {
            acc[appGroup.name] = [];
          }
          acc[appGroup.name].push(app);
        }
      });
      return acc;
    }, {});
  };

  const allowedUserApps = useMemo(() => {
    const groupedMatches = groupAppsByCategory(tools, appsWithScope);
    return Object.entries(groupedMatches)
      .map(([name, apps]) => ({ name, apps }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [appsWithScope, tools]);

  if (!open) {
    return null;
  }

  if (hasError) {
    return (
      <>
        <Container
          tabIndex={-1}
          data-testid="appSwitcher-overlay"
          ref={containerRef}
        >
          <ExceptionContainer>
            <EmptyState.SomethingWentWrong width={128} height={128} />
            <Typography.Body style={{ textAlign: 'center' }}>
              {formatMessage({ id: 'AppSwitcher.error' })}
            </Typography.Body>
          </ExceptionContainer>
          <ButtonContainer>
            <Button variant="secondary" onClick={fetchTools}>
              {formatMessage({ id: 'AppSwitcher.reload' })}
            </Button>
          </ButtonContainer>
        </Container>
        <Dimmer style={{ zIndex: 350 }} />
      </>
    );
  }

  if (loading) {
    return (
      <>
        <Container
          tabIndex={-1}
          data-testid="appSwitcher-overlay"
          ref={containerRef}
        >
          <ExceptionContainer>
            <LoadingDots data-testid="loading-dots" size="xxlarge" />
          </ExceptionContainer>
        </Container>
        <Dimmer style={{ zIndex: 350 }} />
      </>
    );
  }

  const handleTrackMenuClick = (appName: string) => {
    analyticsElementClicked.track({
      isCoreEvent: true,
      elementLocationType: 'MENU' as ElementLocationType,
      elementLocationName: 'APP_SWITCHER',
      elementLabel: appName,
      elementName: 'MenuItem',
      elementType: 'MENU_OPTION' as ElementType,
    });
  };

  return (
    <>
      <Container
        tabIndex={-1}
        data-testid="appSwitcher-overlay"
        ref={containerRef}
      >
        {allowedUserApps.map((category: userApps) => (
          <Fragment key={category.name}>
            <Column css={{ padding: '0 $6 $4 $6' }}>
              <CategoryTitle variant="h6">{category.name}</CategoryTitle>
              <MenuItems>
                {category.apps.map((app) => (
                  <MenuButton
                    active={currentPath === getFirstPath(app.appRoute)}
                    to={app.appRoute}
                    onClick={() => {
                      handleClose();
                      handleTrackMenuClick(app.defaultAppName);
                    }}
                    key={app.identifier}
                  >
                    <AppIcon icon={app.icon} />
                    <MenuItem variant="large" style={{ fontWeight: 500 }}>
                      {app.name}
                    </MenuItem>
                  </MenuButton>
                ))}
              </MenuItems>
            </Column>
            <Divider />
          </Fragment>
        ))}
      </Container>
      <Dimmer style={{ zIndex: 350 }} />
    </>
  );
};

export default AppSwitcher;
