import { useGetCluster } from 'admin-portal-shared-services';
import { useEnvContext } from 'contexts';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useGenerateAppMenuItems, useNavigateLogout } from '../index';

interface GenerateAvatarMenuInterface {
  text: string;
  onClick: () => void;
  id: string;
}

export function useGenerateAvatarMenu(): Array<GenerateAvatarMenuInterface> {
  const apps = useGenerateAppMenuItems();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const navigateLogout = useNavigateLogout();
  const { termsAndConditions, privacyPolicy } = useEnvContext();
  const cluster = useGetCluster();

  const isGlobal = cluster === 'Global_US';
  function sortByDate(a, b) {
    return new Date(a).getTime() - new Date(b).getTime();
  }

  const hasTermsAndPrivacyProps = !!termsAndConditions && !!privacyPolicy;

  const latestTermsDate =
    hasTermsAndPrivacyProps &&
    Object.keys(termsAndConditions)?.sort(sortByDate)[
      Object.keys(termsAndConditions).length - 1
    ];

  const latestPrivacyDate =
    hasTermsAndPrivacyProps &&
    Object.keys(privacyPolicy)?.sort(sortByDate)[
      Object.keys(privacyPolicy).length - 1
    ];

  const privacyPolicyUrl = privacyPolicy?.[latestPrivacyDate];
  const termsAndConditionsUrl = termsAndConditions?.[latestTermsDate];

  const hasAccess = (appId: string) => apps.some((app) => app.appId === appId);

  const hasAccessToAppCatalog = hasAccess('BEES_HUB_APP_CATALOG');
  const hasAccessToAdminTool = hasAccess('ADMINTOOL');
  const hasAccessToApprovalFlow =
    hasAccess('BEES_HUB_APPROVAL_FLOW') && cluster !== 'Restricted_US';

  const allMenuItems = {
    userProfile: {
      text: formatMessage({ id: 'AvatarMenu.userProfile' }),
      onClick: () => navigate('/account'),
      id: 'userProfile',
    },
    signOut: {
      text: formatMessage({ id: 'AvatarMenu.signOut' }),
      onClick: navigateLogout,
      id: 'signOut',
    },
    approvalFlow: {
      text: formatMessage({ id: 'ApprovalFlow.showApprovalFlowButton' }),
      onClick: () => navigate('/approval-flow'),
      id: 'approvalFlow',
    },
    appCatalog: {
      text: formatMessage({ id: 'AppCatalog.showToolLibraryButton' }),
      onClick: () => navigate('/tool-library'),
      id: 'appCatalog',
    },
    adminTool: {
      text: formatMessage({ id: 'AdminTool.showAdminToolButton' }),
      onClick: () => navigate('/admin'),
      id: 'adminTool',
    },
    termsAndConditions: {
      text: formatMessage({ id: 'TermsAndPolicies.terms' }),
      onClick: () => open(termsAndConditionsUrl, '_blank', 'noreferrer'),
      id: 'termsAndConditions',
    },
    privacyPolicy: {
      text: formatMessage({ id: 'TermsAndPolicies.policies' }),
      onClick: () => open(privacyPolicyUrl, '_blank', 'noreferrer'),
      id: 'privacyPolicy',
    },
  };

  const userMenuItems = [allMenuItems.userProfile];

  if (hasAccessToApprovalFlow) {
    userMenuItems.push(allMenuItems.approvalFlow);
  }

  if (hasAccessToAppCatalog) {
    userMenuItems.push(allMenuItems.appCatalog);
  }

  if (hasAccessToAdminTool) {
    userMenuItems.push(allMenuItems.adminTool);
  }

  if (isGlobal) {
    userMenuItems.push(allMenuItems.termsAndConditions);
    userMenuItems.push(allMenuItems.privacyPolicy);
  }

  userMenuItems.push(allMenuItems.signOut);

  return userMenuItems;
}
