export function convertToPascalCase(str: string) {
  return str
    .replace(/[^a-zA-Z0-9\s]/g, ' ')
    .replace(/\s+(.)/g, (match, chr) => chr.toUpperCase())
    .replace(/\s/g, '')
    .replace(/^\w/, (chr) => chr.toUpperCase());
}

export function getFirstPath(url: string): string {
  const match = url?.match(/\/([^/]*)/);
  return match ? match[0] : url;
}
