/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useGetCluster, useUserMetadata } from 'admin-portal-shared-services';
import { useEnvContext } from 'contexts';

export function useTermsAndPrivacy() {
  const { data } = useUserMetadata();
  const { termsAndConditions, privacyPolicy } = useEnvContext();
  const hasTermsAndPrivacyProps = !!termsAndConditions && !!privacyPolicy;
  const cluster = useGetCluster();

  const isGlobal = cluster === 'Global_US';

  function sortByDate(a, b) {
    return new Date(a).getTime() - new Date(b).getTime();
  }

  const latestTermsDate =
    hasTermsAndPrivacyProps &&
    Object.keys(termsAndConditions)?.sort(sortByDate)[
      Object.keys(termsAndConditions).length - 1
    ];

  const latestPrivacyDate =
    hasTermsAndPrivacyProps &&
    Object.keys(privacyPolicy)?.sort(sortByDate)[
      Object.keys(privacyPolicy).length - 1
    ];

  const userAcceptedTerms = data?.acceptedTerms?.reduce((prev, cur) => {
    try {
      prev.push(JSON.parse(cur));
      return prev;
    } catch {
      return prev;
    }
  }, []);

  const isFirstAccess = !userAcceptedTerms?.length;

  const isTermsUpdated = !userAcceptedTerms
    ?.filter((doc) => doc.type === 't&c')
    .map((doc) => doc.date)
    .includes(latestTermsDate);

  const isPrivacyUpdated = !userAcceptedTerms
    ?.filter((doc) => doc.type === 'privacy')
    .map((doc) => doc.date)
    .includes(latestPrivacyDate);

  const shouldOpenTermsAndPrivacyModal =
    isGlobal &&
    hasTermsAndPrivacyProps &&
    (isFirstAccess || isTermsUpdated || isPrivacyUpdated) &&
    !!data;

  return {
    isFirstAccess,
    isTermsUpdated,
    isPrivacyUpdated,
    shouldOpenTermsAndPrivacyModal,
  };
}
