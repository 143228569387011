import { Typography } from '@hexa-ui/components2';
import { styled } from '@hexa-ui/theme';
import { NavLink } from 'react-router-dom';

export const Container = styled('div', {
  position: 'absolute',
  overflow: 'auto',
  width: '100%',
  top: '0',
  left: '0',
  height: 'calc(100vh - 56px)',
  maxWidth: '350px',
  backgroundColor: '$neutral0',
  boxShadow: '0 4px 8px 0 rgba(0,0,0,0.16)',
  zIndex: '$10',
  boxSizing: 'border-box',
  marginTop: '56px',
});

export const Column = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const MenuButton = styled(NavLink, {
  all: 'unset',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  borderRadius: '$full',
  padding: '8px 16px 8px 12px',
  '&:hover': {
    backgroundColor: '#FFFFF0',
  },
  '&:active': {
    backgroundColor: '#FFFF00',
  },
  '&:focus': {
    backgroundColor: '#FFFFF0',
  },
  variants: {
    active: {
      true: {
        backgroundColor: '#FFFF00 !important',
      },
    },
  },
});

export const CategoryTitle = styled(Typography.Header, {
  margin: '1rem 0',
  color: '#1414148F',
});

export const MenuItem = styled(Typography.Body, {
  fontSize: '16px',
  margin: '0',
  fontWeight: 500,
  color: '#141414',
});

export const ExceptionContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '88px 47px 0 47px',
});

export const ButtonContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const MenuItems = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
});
