import { Card as HexaCard } from '@hexa-ui/components';
import { Typography } from '@hexa-ui/components2';
import { styled } from '@hexa-ui/theme';

export const Card = styled(HexaCard, {
  width: '100%',
  padding: '20px 0px',
  display: 'flex',
  flexDirection: 'column',
  height: '368px',
  position: 'relative',
});

export const List = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  padding: '0px 12px 0px 24px',
  marginBottom: '32px',
  scrollbarWidth: 'thin',
  scrollbarColor: 'rgba(219, 218, 218, 1) transparent',
  scrollbarGutter: 'stable',

  '&::-webkit-scrollbar': {
    width: '12px',
  },

  '&::-webkit-scrollbar-track': {
    background: 'transparent',
    borderRadius: '10px',
  },

  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(219, 218, 218, 1)',
    borderRadius: '10px',
  },
});

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
});

export const EmptyStateText = styled(Typography.Body, {
  maxWidth: '261px',
  textAlign: 'center',
});
