import appHeader from 'i18n/messages/appHeader';
import MessageMap from '../i18n.d';

const ptBR: MessageMap = {
  Components: {
    Sidebar: {
      collapseMenu: 'Recolher menu',
    },
  },
  test: {
    translate: 'Tradução',
  },
  Error500: {
    title: 'Opa! Algo deu errado',
  },
  Error403: {
    title: 'Hmm... Acesso negado!',
    description: 'Parece que você não tem permissão para acessar esta página.',
    button: 'Início',
  },
  Error404: {
    title: 'Mmm... Parece que esta página se foi',
    description: 'Parece que esta página não foi encontrada ou não existe.',
    button: 'Início',
  },
  Error503: {
    title: 'Hmm... Serviço indisponível!',
    description:
      'Nossos servidores estão indisponíveis temporariamente. Você pode tentar novamente em alguns minutos e a situação já deverá estar normalizada.',
    button: 'Tente novamente',
    infoText: 'Se o problema persistir, você pode <a>relatá-lo.</a>',
  },
  AvatarMenu: {
    signOut: 'Sair',
    userProfile: 'Perfil do usuário',
  },
  Dashboard: {
    errorPage: 'Nada para ver aqui',
    quickLinks: 'Links rápidos',
    quickLinksLoadingError:
      'Ocorreu um erro e não foi possível carregar os links rápidos.',
    reload: 'Recarregar',
    searchPlaceholder: 'Pesquisar por nome da ferramenta',
    sortBy: 'Classificar por',
    titleAZ: 'Título A-Z',
    titleZA: 'Título Z-A',
    noToolFound: 'Nenhuma ferramenta encontrada.',
  },
  AppSwitcher: {
    yourApps: 'Suas aplicações',
    tools: 'Ferramentas',
    reload: 'Recarregar',
    error: 'Ocorreu um erro e não conseguimos carregar o menu.',
  },
  UpdatesPopover: {
    updates: 'ATUALIZAÇÕES',
    title: 'Novo menu BEES One',
    description:
      'Agora você pode navegar facilmente entre suas ferramentas neste menu. Experimente!',
    pagination: '1 de 1',
    gotIt: 'Entendi',
  },
  AppSwitcherButton: {
    chooseAnApp: 'Selecione um app',
  },
  Home: {
    greetings: 'Olá, {name}!',
    description:
      'Você está no BEES One, uma plataforma completa que oferece várias soluções.',
  },
  Notification: {
    title: 'Notificações',
    allTab: 'Todas',
    unreadTab: 'Não lido',
    highPriorityTab: 'Alta prioridade',
    markAllRead: 'Marcar tudo como lido',
    markUnread: 'Marcar como não lida',
    markRead: 'Marcar como lida',
    highPriority: 'Prioridade',
    noNotifications: 'Ainda não há notificações.',
    noUnreadNotifications: 'Nenhuma notificação não lida ainda.',
    noHighPriorityNotifications:
      'Nenhuma notificação de alta prioridade ainda.',
    noMoreNotifications: 'Não há mais notificações para exibir.',
    showMore: 'Mostrar mais',
    yesterday: 'Ontem, {value}',
    passwordChanged: 'Senha alterada com sucesso!',
    latestNotifications: 'Notificações mais recentes',
    showAll: 'Mostrar todas as notificações',
    error:
      'Ocorreu um erro e não foi possível carregar as notificações mais recentes.',
    reload: 'Recarregar',
  },
  ApprovalFlow: {
    showApprovalFlowButton: 'Approval flow',
  },
  AppCatalog: {
    showToolLibraryButton: 'Mostrar catálogo de aplicativos',
  },
  AdminTool: {
    showAdminToolButton: 'Ferramenta de administração BEES One',
  },
  TermsAndConditions: {
    showTermsAndConditionsButton: 'Terms & Conditions',
  },
  PrivacyPolicy: {
    showPrivacyPolicyButton: 'Privacy Policy',
  },
  TermsAndPolicies: {
    titleFirstLogin: 'Termos e condições e Política de privacidade',
    titleUpdate:
      'Atualização dos Termos e condições e da Política de privacidade',
    descriptionParagraph1:
      'Para continuar, confirme que leu e entendeu os Termos e condições e a Política de privacidade do BEES ONE marcando as caixas abaixo.',
    descriptionParagraph2:
      'Ambos os documentos são clicáveis e exibidos em vários idiomas. Você sempre pode verificar os termos novamente navegando até o menu de configurações.',
    agreeTerms: 'Li e entendi os {link} do BEES One.',
    agreePolicies: 'Li e entendi a {link} do BEES One.',
    terms: 'Termos e condições',
    policies: 'Política de privacidade',
    termsLink: 'Termos e condições',
    policiesLink: 'Política de privacidade',
    termsError: 'Você precisa concordar com os termos para prosseguir.',
    policiesError: 'Você precisa concordar com a política para prosseguir.',
    declineButton: 'Recusar',
    agreeButton: 'Prosseguir',
  },
  ...appHeader.ptBR,
};

export default ptBR;
