import { Card, Grid } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';
import { breakpoints } from 'utils/constants';

export const GreetingsCard = styled(Card, {
  width: '100%',
  padding: '16px',
  display: 'flex',
  gap: '24px',
});

export const GreetingsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',
  justifyContent: 'center',
});

export const GridItem = styled(Grid.Item, {
  width: '100%',
  [`@media (min-width: ${breakpoints.desktopSmall})`]: {
    width: '50%',
  },
});
