import { Grid } from '@hexa-ui/components';
import { Typography } from '@hexa-ui/components2';
import {
  DocumentTitle,
  useAuthenticationService,
} from 'admin-portal-shared-services';
import { useIntl } from 'react-intl';
import hiveIcon from '../../assets/hive-icon.svg';
import LatestNotifications from './components/LatestNotifications';
import { QuickLinks } from './components/QuickLinks';
import {
  GreetingsCard,
  GreetingsContainer,
  GridItem,
} from './Dashboard.styles';

export function Dashboard() {
  const { formatMessage } = useIntl();
  const authenticationService = useAuthenticationService();
  const { given_name: firstName } = authenticationService.getUserFullNameB2C();

  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', padding: '24px 0px' }}
    >
      <DocumentTitle>Home</DocumentTitle>
      <Grid.Container type="fluid" style={{ width: '1328px', rowGap: '16px' }}>
        <Grid.Item style={{ width: '100%' }}>
          <GreetingsCard
            elevated="minimal"
            border="medium"
            css={{ flexDirection: 'row' }}
          >
            <img src={hiveIcon} width="56px" alt="BEES One logo" />
            <GreetingsContainer>
              <Typography.Header variant="h4" style={{ margin: '0px' }}>
                {formatMessage({ id: 'Home.greetings' }, { name: firstName })}
              </Typography.Header>
              <Typography.Body variant="large" style={{ margin: '0px' }}>
                {formatMessage({ id: 'Home.description' })}
              </Typography.Body>
            </GreetingsContainer>
          </GreetingsCard>
        </Grid.Item>
        <GridItem>
          <LatestNotifications />
        </GridItem>
        <GridItem>
          <QuickLinks />
        </GridItem>
      </Grid.Container>
    </div>
  );
}

export default Dashboard;
